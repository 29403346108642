import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LazyLoad from 'react-lazyload'
import { Grid, Loader } from 'semantic-ui-react'
import _ from 'lodash'

import {
  fetchPaymentMethod,
  setPaymentMethodName,
  setTotalNumberPaymentMethods,
  selectSearchTerm,
} from '../actions'
import showDefaultLogo from './showDefaultLogo'

class PaymentMethodList extends React.Component {
  currentPaymentMethodCounties = null
  state = {
    rowsToDisplay: 5,
  }
  showMore = this.showMore.bind(this)

  showMore() {
    console.log('called')
    this.setState({ rowsToDisplay: this.state.rowsToDisplay + 5 })
  }

  componentDidMount() {
    this.props.fetchPaymentMethod({
      id: null,
      name: null,
    })
    this.props.selectSearchTerm('')
    document.getElementById('root').scrollIntoView({ behavior: 'smooth' })
  }

  showDetails = (paymentmethod) => {
    return <Link to={`/paymentmethod/${paymentmethod.id}`} />
  }

  replacePaymentGroups = (paymentGroups) => {
    let newPaymentGroups = paymentGroups

    let indexCreditCard = newPaymentGroups.indexOf('Credit Card')
    let indexDebitCard = newPaymentGroups.indexOf('Debit Card')
    let indexCardsAndWallets = newPaymentGroups.indexOf('Cards & Wallets')
    let indexCardAndWallets = newPaymentGroups.indexOf('Card & Wallets')
    let indexCreditAndDebitCards = newPaymentGroups.indexOf(
      'Credit & Debit Cards'
    )
    let indexLoyaltyCards = newPaymentGroups.indexOf('Loyalty cards')

    if (indexCreditCard !== -1) {
      newPaymentGroups[indexCreditCard] = 'Cards'
    }
    if (indexDebitCard !== -1) {
      newPaymentGroups[indexDebitCard] = 'Cards'
    }
    if (indexCardsAndWallets !== -1) {
      newPaymentGroups[indexCardsAndWallets] = 'Cards'
    }
    if (indexCreditAndDebitCards !== -1) {
      newPaymentGroups[indexCreditAndDebitCards] = 'Cards'
    }
    if (indexCardAndWallets !== -1) {
      newPaymentGroups[indexCardAndWallets] = 'Cards'
    }
    if (indexLoyaltyCards !== -1) {
      newPaymentGroups[indexLoyaltyCards] = 'Loyalty'
    }

    newPaymentGroups = _.uniq(newPaymentGroups)
    return newPaymentGroups
  }

  showPlatforms(platforms, searchTerms) {
    if (Array.isArray(platforms)) {
      return <p> {platforms.join(',')} </p>
    } else {
      let platformNames = Object.keys(platforms)
      if (platformNames.includes('PayU Hub')) {
        platformNames = platformNames.filter((item) => item !== 'PayU Hub')
        platformNames.unshift('PayU Hub')
      }

      return platformNames.map((platformname) => {
        var countries = []
        // per platformname find all supported countries
        platforms[platformname].map((country) => {
          return countries.push(country.country)
        })

        return (
          <p key={platformname}>
            {`${
              platformname === 'PayU Hub'
                ? 'PayU Hub'
                : `Use ${platformname} in ${countries.join(', ')}`
            }`}
          </p>
        )
      })
    }
  }

  renderList() {
    return this.props.paymentMethodData.paymentmethods.map(
      (paymentmethod, index) => (
        <Grid.Row className='country-list-row-item' key={index}>
          <Grid.Column className='pm-name-column'>
            <Link to={`/paymentmethod/${paymentmethod.id}`}>
              <div className='pm-logo-container'>
                <LazyLoad>
                  <img
                    onError={showDefaultLogo}
                    alt='logo'
                    src={`/assets/logos/${paymentmethod.id}.png`}
                    className='pm-logo'
                  />
                </LazyLoad>
              </div>
              <p>
                {paymentmethod.paymentMethodName}{' '}
                <span> {paymentmethod.paymentMethodType}</span>
              </p>
            </Link>
          </Grid.Column>

          <Grid.Column className='pm-cell-entry'>
            <p className='country-list'>
              {paymentmethod.countries.map((countriesObject, index) => {
                return (
                  <Link to={`/country/${countriesObject.id}`} key={index}>
                    <span className='country-list-item' key={index}>
                      {countriesObject.countryName}
                    </span>
                  </Link>
                )
              })}
            </p>
          </Grid.Column>
        </Grid.Row>
      )
    )
  }
  render() {
    this.props.setTotalNumberPaymentMethods(
      this.props.paymentMethodData.paymentmethods.length
    )
    return this.props.paymentMethodData.paymentmethods.length ? (
      <div>
        <h2>List of Available Payment Methods</h2>
        <Grid columns={2}>
          <Grid.Row className='main-header-row'>
            <Grid.Column>
              <h6>Payment Method</h6>
            </Grid.Column>
            <Grid.Column>
              <h6>Shopper Countries</h6>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <PerfectScrollbar swipeEasing>
          <div style={{ overflow: 'auto' }} className='ui two column grid'>
            {this.renderList()}
          </div>
        </PerfectScrollbar>
      </div>
    ) : (
      <div>
        <Loader active>Loading</Loader>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    paymentMethodData: state.paymentMethodData.data,
  }
}

export default connect(mapStateToProps, {
  // createFuseSearchInstance,
  // fetchPaymentData,
  fetchPaymentMethod,
  setPaymentMethodName,
  selectSearchTerm,
  setTotalNumberPaymentMethods,
})(PaymentMethodList)
